label {
  font-family: "Roboto";
  font-weight: 400 !important;
}

legend {
  font-family: "Roboto";
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.twitter {
  color: #55acee !important;
}

.facebook {
  color: #3b5999 !important;
}

.linkedin {
  color: #0077b5 !important;
}

.instagram {
  color: #e4405f !important;
}

.radioButtons > label {
  margin-right: 2rem;
}

.volunteer-radios {
  display: flex;
  flex-direction: column;
}

.volunteer-radios > label {
  margin-left: 0 !important;
  margin-top: 0.5rem;
}

.copyright {
    margin-bottom: 4rem;
}